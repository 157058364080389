var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [!_vm.info ? _c('vue-tooltip', {
    attrs: {
      "displayText": _vm.getHumanizeDate || '-',
      "text": _vm.formattedTooltipText || '-'
    }
  }) : _vm._e(), _vm.info ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('span', _vm._g({}, on), [_vm._v(" " + _vm._s(_vm.getHumanizeDate || "-") + " ")])];
      }
    }], null, false, 2571955353)
  }, [_c('div', [_c('div', [_vm._v(" " + _vm._s(_vm.formattedTooltipText || "-") + " ")]), _c('div', [_vm._v("Archive Reason: " + _vm._s(_vm.info))])])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }