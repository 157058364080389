<template>
  <span>
    <vue-tooltip
      v-if="!info"
      :displayText="getHumanizeDate || '-'"
      :text="formattedTooltipText || '-'"
    />
    <v-tooltip v-if="info" bottom>
      <template #activator="{ on }">
        <span v-on="on">
          {{ getHumanizeDate || "-" }}
        </span>
      </template>
      <div>
        <div>
          {{ formattedTooltipText || "-" }}
        </div>
        <div>Archive Reason: {{ info }}</div>
      </div>
    </v-tooltip>
  </span>
</template>

<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import VueTooltip from "@/view/components/Common/vueTooltip";

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  components: { VueTooltip },
  props: {
    date: {
      type: [String, Number],
      required: true,
    },
    isExpired: {
      type: Boolean,
      default: false,
    },
    info: {
      type: String,
      default: null,
    },
    timezone: {
      type: String,
      default: null,
    },
  },
  computed: {
    getHumanizeDate() {
      const date = this.isExpired
        ? this.getDateInSystemTimezone.add(1, "day")
        : this.getDateInSystemTimezone;
      return date?.fromNow();
    },
    formattedTooltipText() {
      const systemTime = `${this.getFormattedDate(
        this.getDateInSystemTimezone
      )} (UTC)`;
      const localTime = this.timezone
        ? `${this.getFormattedDate(this.getDateInLocalTimezone)} (${
            this.timezone
          })`
        : null;
      return [systemTime, localTime].filter(Boolean).join("\n");
    },
    getDateInSystemTimezone() {
      return this.getBaseDate()?.utc();
    },
    getDateInLocalTimezone() {
      return this.getBaseDate()?.tz(this.timezone);
    },
  },
  methods: {
    getBaseDate() {
      return this.date ? dayjs(this.date * 1000) : null;
    },
    getFormattedDate(date) {
      return date?.format("MMM DD, YYYY hh:mm a") || null;
    },
  },
};
</script>
